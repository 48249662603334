<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div>
                <div class="flex flex-wrap items-center content-between justify-between">
                    <span class="font-semibold text-base">Data Tiket</span>
                    <div class="flex">
                        <div class="text-xs relative">
                            <div class="absolute top-2.5 left-2.5">
                                <unicon name="search" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>
                            </div>
                            <input v-model="keyword" type="text"
                                class="block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs"
                                name="keyword" ref="keyword" id="keyword" @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                        </div>
                        <div>
                            <button class="text-white px-4 py-2 bg-blue-500 rounded ml-4"
                                @click="$router.push('/tiket/add')">Tambah</button>
                        </div>
                    </div>
                </div>
                <div class="mt-4 w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                <td>Tiket ID</td>
                                <td class="py-4">Judul Keluhan</td>
                                <td>Nama Pelanggan</td>
                                <td>Prioritas</td>
                                <td>Status</td>
                                <td>Status Pengerjaan</td>
                                <td>Tiket Dibuat</td>
                                <td>Tiket Update</td>
                                <td>Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id"
                                class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                <td>
                                    <span v-if="item.tiket_id">
                                        <!-- Clickable tiket_id if it exists, with blue color -->
                                        <router-link :to="'/tiket/detail/' + item.id"
                                            class="text-blue-600 hover:underline">
                                            {{ item.tiket_id }}
                                        </router-link>
                                    </span>
                                    <span v-else class="text-black">
                                        <!-- Non-clickable N/A with black color -->
                                        N/A
                                    </span>
                                </td>
                                <td class="py-4">
                                    <div class="flex items-center">
                                        <div>
                                            <p>{{ item.name }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>{{ item.Customer }}</span>
                                </td>
                                <td>
                                    <span :class="getPriorityBadge(item.priority_name)">
                                        {{ item.priority_name }}
                                    </span>
                                </td>
                                <td>
                                    <!-- Status Badge for status_name -->
                                    <span :class="getStatusBadge(item.status_name)">
                                        {{ item.status_name }}
                                    </span>
                                </td>
                                <td>
                                    <!-- Status Process Badge for status_process_name -->
                                    <span :class="getProcessStatusBadge(item.status_process_name)">
                                        {{ item.status_process_name }}
                                    </span>
                                </td>
                                <td>
                                    <span>{{ item.createdAt }}</span>
                                </td>
                                <td>
                                    <span>{{ item.updatedAt }}</span>
                                </td>
                                <td>
                                    <div class="relative inline-block text-left">
                                        <button @click="toggleDropdown(item.id)" type="button"
                                            class="inline-flex items-center px-2 py-2 text-sm font-semibold text-white bg-gray-600 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                            <i class="fa fa-cog px-1"></i> Aksi
                                        </button>

                                        <!-- Dropdown menu -->
                                        <div v-if="isDropdownOpen(item.id)"
                                            class="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div class="py-1">
                                                <!-- Ticket Detail Button -->
                                                <button v-if="item.status_name.toLowerCase() !== 'closed'"
                                                    @click="$router.push('tiket/detail/' + item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-ticket px-2"></i> Ticket
                                                </button>
                                                <!-- Edit Button -->
                                                <button v-if="role_id !== 35"
                                                    @click="$router.push('tiket/edit/' + item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-edit px-2"></i> Edit
                                                </button>
                                                <!-- Change Priority -->
                                                <!-- <button @click="openPriorityModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-flag px-2"></i> Ubah Prioritas
                                                </button> -->
                                                <button @click="showPriorityModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-flag px-2"></i> Ubah Prioritas
                                                </button>

                                                <!-- Change Status -->
                                                <button @click="openStatusModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-exchange px-2"></i> Ubah Status
                                                </button>

                                                <!-- Change Progress -->
                                                <button @click="openProcessModal(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                    <i class="fa fa-spinner px-2"></i> Ubah Proses
                                                </button>

                                                <!-- Delete Button (Hidden for role_id 35) -->
                                                <button v-if="role_id !== 35" @click="openDelete(item.id)"
                                                    class="flex items-center w-full px-4 py-2 text-sm text-red-700 hover:bg-gray-100">
                                                    <i class="fa fa-trash px-2"></i> Delete
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </td>


                            </tr>
                        </tbody>
                    </table>
                    <div class="flex justify-end items-center mt-4">
                        <div class="flex items-center">
                            <div class="font-medium">
                                <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                            </div>
                            <div class="flex">
                                <unicon name="angle-left" class="cursor-pointer"
                                    :fill="currentPage > 1 ? '#00000' : '#A1A1AA'" @click="previousPage()"></unicon>
                                <unicon name="angle-right" class="cursor-pointer"
                                    :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                            </div>
                        </div>
                    </div>
                </div>
                <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteData()" v-if="modal_dialog" />
                    <openPriorityModal v-if="isPriorityModalOpen" :ticket-id="selectedTicketId" @close="closePriorityModal" @priorityUpdated="getData" />
                    <openStatusModal v-if="isStatusModalOpen" :ticket-id="selectedTicketId" @close="closeStatusModal('status')" />
                    <openProcessModal v-if="isProcessModalOpen" :ticket-id="selectedTicketId" @close="closeProcessModal('proses')" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global';
import deleteComponent from '../../components/global/deleteComponent.vue';
import priorityComponent from '../../components/global/prioritystatusComponent.vue';
import statusComponent from '../../components/global/tiketstatusComponent.vue';
import processComponent from '../../components/global/process_statusComponent.vue';

export default {
    data: () => ({
        data: [],
        totalRows: 1,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        keyword: '',
        modal_dialog: false, // delete modal
        selectedTicketId: null,
        isPriorityModalOpen: false, // Add this property to manage modal visibility
        isStatusModalOpen: false,
        isProcessModalOpen: false,
        showStatusModal: false,
        showProcessModal: false,
        delete_id: '',
        default_url: process.env.VUE_APP_IMAGES,
        openDropdownId: null,
    }),
    mixins: [globalMixin],
    methods: {
        ...mapActions([]),
        getData() {
            this.axios.get('v1/tiket?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    this.totalRows = ress.data.count;
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },
        openDelete(id) {
            this.delete_id = id;
            this.closeModal();
        },
        showPriorityModal(id) {
      this.selectedTicketId = id;
      this.isPriorityModalOpen = true;
    },
        openStatusModal(id) {
            this.selectedTicketId = id;
            this.isStatusModalOpen = true;
        },
        openProcessModal(id) {
            this.selectedTicketId = id;
            this.isProcessModalOpen = true;
        },
        closePriorityModal() {
      this.isPriorityModalOpen = false;
    },
  closeProcessModal() {
            this.isProcessModalOpen = false;
        },
        closeStatusModal() {
    this.isStatusModalOpen = false;
  },
        deleteData() {
            this.axios.delete('v1/tiket/' + this.delete_id, this.config)
                .then((ress) => {
                    this.closeModal();
                    this.getData();
                    this.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahStatus() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeStatusModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahProcess() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeProcessModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        closeModal() {
            this.modal_dialog = !this.modal_dialog;
        },
        getStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'open':
                    return 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10';
                case 'closed':
                    return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10';
                default:
                    return '';
            }
        },
        toggleDropdown(id) {
            this.openDropdownId = this.openDropdownId === id ? null : id;
        },
        isDropdownOpen(id) {
            return this.openDropdownId === id;
        },
        getProcessStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'on progress':
                    return 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20';
                case 'solved':
                    return 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20';
                case 'pending':
                    return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10';
                case 'hold':
                    return 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10';
                case 'assigned':
                    return 'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
                default:
                    return '';
            }
        },
        getPriorityBadge(priority) {
            switch (priority.toLowerCase()) {
                case 'low':
                    return 'inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800';
                case 'medium':
                    return 'inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800';
                case 'high':
                    return 'inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800';
                case 'critical':
                    return 'inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800';
                default:
                    return '';
            }
        }
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            role_id: 'auth/role_id'
        }),
        role_id() {
            return this.$store.state.auth.user.role_id; // Directly access role_id from Vuex state
        },
    },
    created() {
        this.getData();
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        deleteComponent,
        openStatusModal: statusComponent,
        openProcessModal: processComponent,
        openPriorityModal: priorityComponent,
    }
}
</script>
