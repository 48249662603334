<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div class="bg-white rounded-lg p-6 w-96">
        <h3 class="text-lg font-semibold mb-4">Ubah Status Tiket</h3>
        <form @submit.prevent="updateStatus">
          <div class="mb-4">
            <label for="status" class="block text-sm font-medium text-gray-700">Status</label>
            <select v-model="status" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
              <option value="" disabled>Pilih Status Tiket</option>
              <option value="open">Open</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          <div class="flex justify-end space-x-2">
            <button type="button" @click="$emit('close')" class="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Update</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      ticketId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        status: ''
      };
    },
    methods: {
      uupdateStatus() {
    console.log('Updating status...');
    this.axios.put(`/v1/tiket/${this.id}/status`, { status: this.status })
      .then(response => {
        console.log('Status updated successfully!');
        this.$emit('close');
        this.$snack.success({ text: 'Status updated successfully!' });
        this.$emit('update');
      })
      .catch(error => {
        console.error('Failed to update status.', error);
        this.$snack.error({ text: 'Failed to update status.' });
      });
  }
    }
  };
  </script>
  