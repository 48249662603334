<template>
    <div class="fixed inset-0 z-100 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div class="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <div class="flex justify-between items-center border-b pb-2 mb-4">
          <h2 class="text-lg font-semibold">Ubah Prioritas Tiket</h2>
          <button @click="$emit('close')" class="text-gray-400 hover:text-gray-600">
            <i class="fas fa-times"></i>
          </button>
        </div>
  
        <!-- Priority Selection -->
        <div class="mb-4">
          <label for="priority" class="block text-sm font-medium text-gray-700">Prioritas</label>
          <select v-model="priority_id" id="priority" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm">
            <option value="" disabled>Pilih Status Prioritas</option>
            <option v-for="priority in priorities" :key="priority.id" :value="priority.id">
              {{ priority.name }}
            </option>
          </select>
        </div>
  
        <!-- Submit and Cancel Buttons -->
        <div class="flex justify-end space-x-2">
          <button @click="updatePriority" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Simpan</button>
          <button @click="$emit('close')" class="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">Batal</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      ticketId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        priority_id: '', // Selected priority ID
        priorities: [
          { id: 1, name: 'Low' },
          { id: 2, name: 'Medium' },
          { id: 3, name: 'High' },
          { id: 4, name: 'Critical' },
        ], // Sample priority data, can be fetched dynamically if needed
      };
    },
    methods: {
      updatePriority() {
        // Perform the API call to update priority
        this.axios
          .put(`v1/tiket/ubahStatusPriority/${this.ticketId}`, {
            priority_id: this.priority_id,
          })
          .then((response) => {
            this.$emit('close'); // Close modal on success
            this.$emit('priorityUpdated', response.data); // Emit event to notify parent
            this.$snack.success({ text: 'Prioritas berhasil diubah!' });
          })
          .catch((error) => {
            this.$snack.error({ text: 'Gagal mengubah prioritas.' });
          });
      },
    },
  };
  </script>