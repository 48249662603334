<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white rounded-lg p-6 w-96">
            <h3 class="text-lg font-semibold mb-4">Ubah Status Progress</h3>
            <form @submit.prevent="updateProgress">
                <div class="mb-4">
                    <label for="progress" class="block text-sm font-medium text-gray-700">Progress</label>
                    <select v-model="progress" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                        <option value="" disabled>Pilih Status Pengerjaan</option>
                        <option value="on progress">On Progress</option>
                        <option value="solved">Solved</option>
                        <option value="pending">Pending</option>
                        <option value="hold">Hold</option>
                        <option value="assigned">Assigned</option>
                    </select>
                </div>
                <div class="flex justify-end space-x-2">
                    <button type="button" @click="$emit('close')" class="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                    <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Update</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ticketId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            progress: ''
        };
    },
    methods: {
        updateProgress() {
            this.axios.put(`/v1/tiket/${this.ticketId}`, { progress: this.progress })
                .then(response => {
                    this.$emit('close');
                    this.$snack.success({ text: 'Progress updated successfully!' });
                    this.$emit('update');
                })
                .catch(error => {
                    this.$snack.error({ text: 'Failed to update progress.' });
                });
        }
    }
}
</script>
